export default {
    methods: {
        getDocumentUrl(documentId, download = false) {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/documents/${documentId}${download ? '?download=1' : ''}`;
        },

        getMediaUrl(documentId, download = false) {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/media/${documentId}${download ? '?download=1' : ''}`;
        },

        getDocumentIcon(document) {
            const extension = document.filename.split('.').pop().toLowerCase();
            switch (extension) {
                case 'pdf':
                    return 'mdi-file-pdf-box';
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'gif':
                    return 'mdi-file-png-box';
                default:
                    return 'mdi-file';
            }
        }
    }
};
