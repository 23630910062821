<template>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-subtitle class="green--text">
                <v-tooltip :disabled="!appointment.comments" top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            {{ appointment.subject }}
                        </span>
                    </template>
                    {{ appointment.comments }}
                </v-tooltip>
            </v-list-item-subtitle>

            <v-list-item-subtitle>
                <small>
                    Rendez-vous le {{ appointment.instance.startDate | toDatetime(false) }},
                    <template v-if="appointment.duration">
                        durée : {{ appointment.duration | minutesToHours() }},
                    </template>
                    <template v-if="appointment.location">
                        lieu: {{ appointment.location }},
                    </template>
                    <template v-if="appointment.stakeholder.fullname">
                        avec : {{ appointment.stakeholder.fullname }}
                    </template>
                </small>
            </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            <CancelAppointmentBuyerDialog :appointment="appointment" @canceled="$emit('reload')">
                <template v-slot:activator="{ on: dialog }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{ ...dialog, ...tooltip }">
                                <v-icon> mdi-calendar-remove </v-icon>
                            </v-btn>
                        </template>
                        Annuler le rendez-vous
                    </v-tooltip>
                </template>
            </CancelAppointmentBuyerDialog>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import CancelAppointmentBuyerDialog from './CancelAppointmentBuyerDialog.vue';

export default {
    name: 'AppointmentsListItem',

    props: {
        appointment: { type: Object, required: true }
    },

    components: {
        CancelAppointmentBuyerDialog
    }
};
</script>