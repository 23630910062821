export default {
    computed: {
        userMessageRecipients() {
            let recipients = [];

            const recipientTypes = this.getConfig('messages.stakeholders_messages_recipients_types', []);
            this.selectedSale.stakeholders.forEach((s) => {
                if (recipientTypes.includes(s.type2)) {
                    recipients.push(s);
                }
            });

            if (this.getConfig('messages.hide_sellers_after_deeddate', false) && this.selectedSale.deeddate) {
                recipients = recipients.filter((r) => {
                    !['seller.main', 'seller.other'].includes(r.type2);
                });
            }

            for (const intervenant of this.selectedSale.intervenants) {
                intervenant.type = 'user';
                recipients.push(intervenant);
            }

            const uniques = [];
            for (const recipient of recipients) {
                if (!uniques.find((u) => u.id === recipient.id)) {
                    uniques.push(recipient);
                }
            }

            return uniques;
        }
    }
};
