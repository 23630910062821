<template>
    <v-dialog v-model="isOpen" width="400px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Prendre un rendez-vous
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                Etes-vous sûr de vouloir prendre un rendez-vous ?
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="blue" small dark> Oui </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Non </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { addMinutes } from 'date-fns';

export default {
    name: 'CreateAppointmentInstanceValidationDialog',

    props: {
        disponibility: { type: Object, required: true },
        startDate: { type: Date, required: true }
    },

    data: () => ({
        isOpen: false
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);
                const body = {
                    instance: {
                        appointmentId: this.disponibility.appointment.id,
                        stakeholderId: this.disponibility.stakeholder.id,
                        startDate: this.startDate,
                        endDate: addMinutes(this.startDate, this.disponibility.appointment.duration)
                    }
                };

                const { success, err } = await this.repos.appointments.createAppointmentInstance(body);
                if (success) {
                    this.$notify({
                        title: 'Information',
                        text: 'votre rendez-vous à bien été planifié',
                        type: 'success'
                    });
                    this.$nextTick(() => {
                        this.$emit('created');
                    });
                    this.isOpen = false;
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la saisie du rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>