export default {
    computed: {
        totalCalledPercent() {
            if (this.getSalePriceTTC(this.selectedSale) === 0) {
                return 0;
            } else {
                return this.getSaleTotalCalled(this.selectedSale) / this.getSalePriceTTC(this.selectedSale) * 100;
            }
        },

        totalPaidPercent() {
            if (this.getSaleTotalCalled(this.selectedSale) === 0) {
                return 0;
            } else {
                return this.totalPaid / this.getSaleTotalCalled(this.selectedSale) * 100;
            }
        },

        amountToPayPercent() {
            if (this.totalPaid === 0) {
                return 0;
            } else {
                return 100 - (this.totalPaid / this.getSalePriceTTC(this.selectedSale)) * 100;
            }
        },

        totalPaid() {
            return this.selectedSale.payments.reduce((sum, item) => {
                if (!isNaN(item.amount) && !item.depositId) {
                    return sum + item.amount;
                } else {
                    return sum;
                }
            }, 0);
        }
    }
};
