<template>
    <v-list>
        <v-row v-if="toPlan.length > 0" align="center">
            <v-divider class="mr-2 ml-5" />
            <small class="grey--text"> Rendez-vous à planifier </small>
            <v-divider class="ml-2 mr-5" />
        </v-row>

        <AppointmentListItemToPlan v-for="appointment of toPlan" :key="appointment.id" :appointment="appointment" @reload="fetchAppointments()" />

        <v-row v-if="planned.length > 0" align="center">
            <v-divider class="mr-2 ml-5" />
            <small class="grey--text"> Rendez-vous à venir </small>
            <v-divider class="ml-2 mr-5" />
        </v-row>

        <AppointmentsListItemPlanned v-for="appointment of planned" :key="appointment.id" :appointment="appointment" @reload="fetchAppointments()" />

        <v-row v-if="done.length > 0" align="center">
            <v-divider class="mr-2 ml-5" />
            <small class="grey--text"> Rendez-vous passés </small>
            <v-divider class="ml-2 mr-5" />
        </v-row>

        <AppointmentListItemDone v-for="appointment of doneLimited" :key="appointment.id" :appointment="appointment" />

        <v-list-item v-if="!displayMoreDone && done.length > 1" class="no-min-height">
            <v-list-item-subtitle>
                <v-btn @click="displayMoreDone = true" x-small depressed>
                    Voir plus...
                </v-btn>
            </v-list-item-subtitle>
        </v-list-item>

        <v-list-item v-if="displayMoreDone && done.length > 1" class="no-min-height">
            <v-list-item-subtitle>
                <v-btn @click="displayMoreDone = false" x-small depressed>
                    Voir moins...
                </v-btn>
            </v-list-item-subtitle>
        </v-list-item>
    </v-list>
</template>

<script>
import AppointmentListItemDone from './AppointmentListItemDone.vue';
import AppointmentListItemToPlan from './AppointmentListItemToPlan.vue';
import AppointmentsListItemPlanned from './AppointmentsListItemPlanned.vue';

export default {
    name: 'AppointmentsList',

    components: {
        AppointmentListItemDone,
        AppointmentListItemToPlan,
        AppointmentsListItemPlanned
    },

    data: () => ({
        appointments: [],
        displayMoreDone: false
    }),

    computed: {
        toPlan() {
            return this.appointments.filter((a) => !a.instance || !a.instance.id || [11, 12].includes(a.instance.status));
        },
        planned() {
            return this.appointments.filter((a) => a.instance && a.instance.id && a.instance.status === 0);
        },
        done() {
            return this.appointments.filter((a) => a.instance && a.instance.id && a.instance.status === 255);
        },
        doneLimited() {
            if (this.displayMoreDone) {
                return this.done;
            } else {
                return this.done.slice(0, 1);
            }
        }
    },

    methods: {
        async fetchAppointments() {
            try {
                this.setLoading(true);

                const query = {
                    include: 'programs,categories',
                    sales: [this.selectedSale.id],
                    hidePastAppointments: '1'
                };

                const { appointments, err } = await this.repos.appointments.getAppointments(query);
                if (err) {
                    throw new Error(err);
                }
                this.appointments = appointments;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    created() {
        this.fetchAppointments();
    }
};
</script>

<style lang="scss" scoped>
.no-min-height {
    min-height: 0;
}
</style>