export default {
    data: () => ({
        saleStatuses: [
            { id: 'prereserved', label: 'Pré réservé', statusDateLabel: '', color: 'yellow darken-3', icon: 'fas fa-file' },
            { id: 'reserved', label: 'Réservé', statusDateLabel: '', color: 'light-blue', icon: 'fas fa-file-contract' },
            { id: 'sruwait', label: 'Attente SRU', statusDateLabel: '', color: 'blue', icon: 'fa fa-pause' },
            { id: 'loanwait', label: 'Attente prêt', statusDateLabel: '', color: 'cyan', icon: 'fas fa-piggy-bank' },
            { id: 'setnotaryapp', label: 'Fixer RDV notaire', statusDateLabel: '', color: 'light-green', icon: 'fa fa-calendar' },
            { id: 'notaryappset', label: 'RDV notaire fixé', statusDateLabel: '', color: 'green', icon: 'fa fa-calendar-check' },
            { id: 'signed', label: 'VEFA signée', statusDateLabel: '', color: 'teal', icon: 'fa fa-feather-alt' },
            { id: 'desisted', label: 'Désistée', statusDateLabel: '', color: 'red lighten-2', icon: 'fas fa-stop-circle' },
            { id: 'retracted', label: 'Retracté', statusDateLabel: '', color: 'red lighten-2', icon: 'fas fa-stop-circle' },
            { id: 'canceled', label: 'Annulée', statusDateLabel: '', color: 'red lighten-2', icon: 'fas fa-stop-circle' },
            { id: 'resolved', label: 'Résolue', statusDateLabel: '', color: 'red lighten-2', icon: 'fas fa-stop-circle' }
        ],

        saleSteps: [
            { step: 1, label: 'Signature contrat' },
            { step: 2, label: 'Délai SRU' },
            { step: 3, label: 'Financement' },
            { step: 4, label: 'Acte authentique' },
            { step: 5, label: 'Appels de fonds' },
            { step: 6, label: 'Livraison' }
        ]
    }),

    methods: {
        getSaleStep(step, key) {
            const item = this.saleSteps.find((s) => s.step === step);
            if (item) {
                return item[key];
            } else {
                return '';
            }
        },

        getSignatureFromBuyer(sale, buyer) {
            return sale.signatures.find((signature) => {
                return buyer.type === signature.userType && buyer.id === signature.userId;
            });
        },

        getConnectedUserSignature(sale) {
            const email = this.$store.state.application.user.email;
            const buyer = sale.buyers.find((s) => s.email === email);
            return this.getSignatureFromBuyer(sale, buyer) || {};
        },

        getSalePriceTTC(sale) {
            let sum = sale.products.reduce((sum, product) => {
                sum += parseFloat(product.salePrice);
                return sum;
            }, 0);


            sum = sale.tmas.reduce((sum, tma) => {
                const priceTotalHT = tma.priceHT * tma.quantity;
                const priceTotalTTC = priceTotalHT + (priceTotalHT * tma.vat / 100);
                if (!isNaN(priceTotalTTC)) {
                    return sum + priceTotalTTC;
                } else {
                    return sum;
                }
            }, sum);

            return sum;
        },

        getSaleLastCallForFunds(sale) {
            if (sale && Array.isArray(sale.timetable)) {
                const rows = sale.timetable.filter((t) => t.fundcall && t.fundcall.dateSent && t.fundcall.amount);
                return rows[rows.length - 1];
            }
        },

        getSaleLastPayment(sale) {
            if (sale && Array.isArray(sale.payments)) {
                const rows = sale.payments.filter((t) => t.amount);
                return rows[rows.length - 1];
            }
        },

        getSaletotalPaid(sale) {
            return sale.payments.reduce((sum, item) => {
                if (!isNaN(item.amount)) {
                    return sum + item.amount;
                } else {
                    return sum;
                }
            }, 0);
        },

        getSaleTotalTma(sale) {
            return sale.tmas.reduce((sum, tma) => {
                return tma.calledamount;
            }, 0);
        },

        getSaleTotalCalled(sale) {
            const called = sale.timetable.reduce((sum, item) => {
                if (item.fundcall && !isNaN(item.fundcall.amount)) {
                    return sum + item.fundcall.amount;
                } else {
                    return sum;
                }
            }, 0);

            const tma = this.getSaleTotalTma(sale);

            return called + tma;
        },

        getSaleCallForFundsDocument(sale, timetableId) {
            return sale.documents.find((d) => d.type === 'timetable' && d.objectId === timetableId);
        },

        getOtherUsersSignature(sale) {
            let res = [];
            const email = this.$store.state.application.user.email;
            const buyers = sale.buyers.filter((s) => s.email !== email);
            buyers.forEach(b => {
                const sig = sale.signatures.find((s) => s.userType === b.type && s.userId === b.id);
                if (sig) {
                    sig.name = b.name;
                    sig.firstname = b.firstname;
                    res.push(sig);
                }
            });
            return res;
        },

        getPromoteurSignature(sale) {
            return sale.signatures.find((s) => s.userType === 3) || {};
        }
    }
};
